import React, {useState} from 'react';
import Form from '@marcster/react-form';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import elements from '../data/form.json';

import errorIcon from '../img/error.svg';
import successIcon from '../img/success.svg';

const Home = () => {
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [message, setMessage] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const success = r => {
    setMessage({
      img: successIcon,
      title: 'Thank you 🥂',
      message: 'You will receive an email confirmation.'
    });
    setDisabled(true);
    setIsLoading(false);
  }
  const error = e => {
    setMessage({
      img: errorIcon,
      title: 'Oops... 👹',
      message: 'Something went wrong. Please try again later.'
    });
    console.log(e);
    setIsLoading(false);
  }

  const post = async (data) => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha("contact_form");

    setIsLoading(true);
    fetch('/api/subscribe', { method: 'POST', body: JSON.stringify({...data, token}), headers: {'Content-Type': 'application/json' } })
      .then(r => {
        if(r.ok) return r;
        throw new Error(r.status)
      })
      .then(r => success(r))
      .catch(e => error(e));
  }

  return (
    <section>
      <p>
          Pick nick in the park with singing birds <br/>
          flying bees champange and fingerfood...<br/>
          Bring your own stuff, be creative and also bring<br/>
          your beloved single friends! <br/>
          Lets have a chilled get together<br/>
      </p>
      <p style={{marginBottom: '32px'}}><strong>Sign in and get the infos...</strong>
      </p>
      {
        !isLoading && message && <Message {...message}/>
      }
      {
        isLoading && <Loader/>
      }
        <Form className={isLoading || disabled ? 'hidden' : ''} {...{
            post,
            elements: elements.register
          }}/>

    </section>
  )
}

export default Home;
