import React from 'react';
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <section>
      <h2>What data do we collect?</h2>
      <p>You gender, name, email, date and time of your registration. That’s it 😎!</p>
      <h2>How do we collect your data?</h2>
      <p>When you fill out the form ✍️.</p>
      <h2>How will we use your data?</h2>
      <p>Only Lonely Sundays has access to your data.</p>
      <h2>How do we store your data?</h2>
      <p>In a database located in Zurich.</p>
      <h2>What are your data protection rights?</h2>
      <p>Ask Google.</p>
      <h2>What are cookies?</h2>
      <p>Some sort of text files one your device 📱 that helps 🎣 tracking you and other technical stuff.</p>
      <h2>How do we use cookies?</h2>
      <p>This website has no cookies 🍪.</p>
      <h2>What types of cookies do we use?</h2>
      <p>Again, no cookies 🍪 here.</p>
      <h2>How to manage your cookies</h2>
      <p>You don’t have to manage our cookies 🍪 for this site. Nevertheless ask Google.</p>
      <h2>How to contact us</h2>
      <p>hello@lonelysundays.com</p>
      <Link to="/"><i class="zmdi zmdi-long-arrow-left"></i> Back</Link>
    </section>
  )
}

export default Privacy;
