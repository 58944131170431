import React from 'react';
import './Loader.css';
import { ReactComponent as Antenna } from '../img/antenna.svg';

const Loader = () => {
  return (
    <Antenna className="loader"/>
  )
}

export default Loader;
