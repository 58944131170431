import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spring } from 'react-spring';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './css/normalize.css';
import './css/animate.css';
import './css/main.css';
import './fonts/material-design-iconic-font/css/material-design-iconic-font.min.css';

import Home from './routes/Home';
import Privacy from './routes/Privacy';

import logo from './img/logo.svg';

function App() {

  return (
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey="6LePT8MdAAAAAEl4xPpnE6SjaahV_aQoMmkIg4MA">
        <div className="wrapper">
            <div className="inner">
              <img src={logo} className="logo" alt="Lonely Sundays Logo"/>
              <Routes>
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/" element={<Home/>}/>
              </Routes>
            </div>
          </div>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  );
}

export default App;
